export default [
    {
      title: 'breadcrumb.item',
      icon: 'ArchiveIcon',
      children: [
        {
          title: 'breadcrumb.itemCategory',
          route: 'list-category',
          icon: 'GitPullRequestIcon',
          resource: 'item-category',
          action: 'read'
        },
        {
          title: 'breadcrumb.itemType',
          route: 'list-item-type',
          icon: 'ArchiveIcon',
          resource: 'item-type',
          action: 'read'
        },
        {
          title: 'breadcrumb.itemSection',
          route: 'list-section',
          icon: 'FolderIcon',
          resource: 'item-section',
          action: 'read'
        },
        {
          title: 'breadcrumb.item',
          route: 'list-item',
          icon: 'ArchiveIcon',
          resource: 'item',
          action: 'read'
        },
      ],
    }
  ];